<template>
    <div class="content md">
        <a-tabs v-model="key" tab-position="left" style="height: 100%">
            <a-tab-pane :key="1" tab="超管权限">
                <div class="flex-box vertical" style="height: 100%;">
                    <div class="data-title">
                        <div class="rec-title-text">共{{pagination.total}}条记录</div>
                        <a-form-model class="query-form" layout="inline">
                        </a-form-model>
                        <a-space>
                            <a-button @click="getList">查询</a-button>
                            <a-button type="primary" @click="addSuper">添加超管</a-button>
                        </a-space>
                    </div>
                    <div class="data-area">
                        <a-table
                            ref="dataTable"
                            :columns="columns"
                            row-key="id"
                            :data-source="list"
                            :loading="loading"
                            :pagination="pagination"
                            :scroll="{y: h}"
                            @change="handleTableChange">
                            <template v-slot:action="text, record">
                                <div class="row-btn">
                                    <a class="txt-btn danger" @click.stop="delSuper(record)">删除</a>
                                </div>
                            </template>
                        </a-table>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="系统权限">
                <system-admin type="super"></system-admin>
            </a-tab-pane>
        </a-tabs>
        <a-modal
                title="添加超管"
                :width="640"
                :footer="null"
                v-model="visible">
            <form-area
                    ref="adminForm"
                    class="flex-box vertical"
                    layout="horizontal"
                    :label-col="{span: 4}"
                    :wrapper-col="{span: 20}"
                    :items="items"
                    :entity="form"
                    confirm-text="确定"
                    @cancel="cancel()"
                    @confirm="confirm"></form-area>
        </a-modal>
    </div>
</template>

<script>
    import SystemAdmin from "../layouts/SystemAdmin";
    export default {
        name: "Permission",
        components: {
            SystemAdmin
        },
        data() {
            return {
                key: 1,
                list: [],
                form: {},
                loading: false,
                h: 500,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                visible: false,
                columns: [
                    {
                        title: '姓名/法名',
                        dataIndex: 'wx_user_id',
                        customRender: text => <open-data type="userName" openid={text}></open-data>
                    },
                    {title: '操作', key: 'action', scopedSlots: { customRender: 'action'}}
                ],
                items: [
                    {
                        key: 'user',
                        label: '选择成员',
                        component: 'select-book',
                        props: {
                            type: "user"
                        },
                        rules: [{ required: true, message: '请选择成员', trigger: 'change' }]
                    },
                ]
            }
        },
        created() {
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
            cancel() {
                this.visible = false;
            },
            confirm(form) {
                let user = form.user[0];
                this.$axios(`/admin/user?filter[wx_user_id]=${user}`).then(res => {
                    if(res.data.length > 0) {
                        let b = res.data[0];
                        this.$axios({
                            url: `/admin/user/${b.id}`,
                            method: 'PATCH',
                            data: {
                                role: 1
                            }
                        }).then(() => {
                            this.$message.success('添加成功');
                            this.getList();
                            this.visible = false;
                        })
                    } else {
                        this.$message.warning("成员不存在");
                    }
                })
            },
            addSuper() {
                this.form = {};
                this.visible = true;
            },
            delSuper(record) {
                this.$confirm({
                    title: '提示',
                    content: '确定删除此超管吗？',
                    onOk: () => {
                        let url = `/admin/user/${record.id}`;
                        this.$axios({
                            url,
                            method: 'PATCH',
                            data: {role: 0}
                        }).then(() => {
                            this.$message.success('已删除');
                            this.getList();
                        })
                    }
                });

            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                this.loading = true;
                let url = `/admin/user?filter[role]=1`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                this.$axios({url}).then(res => {
                    this.loading = false;
                    let list = res.data;
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style lang="less">
    .ant-tabs-content,
    .ant-tabs-tabpane {
        height: 100%;
    }
</style>
